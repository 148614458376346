// Styles
import { sizes } from "../styles/breakpoints"

/**
 * The function `getMarginBasedOnWidth` returns the appropriate margin and width values for an image
 * based on the given width.
 * @param {number} width - The `width` parameter is a number that represents the width of an element or
 * container.
 * @returns an object with two properties: "imageMargin" and "imageWidth".
 */
export const getMarginBasedOnWidth = (
  width: number
): {
  imageMargin: number
  imageWidth: number
} => {
  if (width < sizes.M) {
    return {
      imageMargin: 0,
      imageWidth: 240,
    }
  } else {
    return {
      imageMargin: 24,
      imageWidth: 320,
    }
  }
}
