import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { scroller, Element } from "react-scroll"
import GatsbyImage from "gatsby-image"

// Components
import ContentWrapper from "./ContentWrapper"
import Icon from "./icons/Icon"

// Constants
import { getMarginBasedOnWidth } from "../utils/getMargin"

// Styles
import colors from "../styles/colors"
import mq from "../styles/breakpoints"
import textStyles from "../styles/textStyles"

// Types
import { ITeamRituals } from "../prismic-types/platform"
import ZigZagLine from "./ZigZagLine"

interface IProps {
  className?: string
  teamRituals: ITeamRituals
}

const TeamRituals: React.FC<IProps> = ({ teamRituals, className }) => {
  const [scrolledToEnd, setScrolledToEnd] = useState<boolean>(false)
  const [isHovered, setIsHovered] = useState<"" | "left" | "right">("")
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 0
  )

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize)
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", handleResize)
      }
    }
  }, [])

  const { imageMargin, imageWidth } = getMarginBasedOnWidth(windowWidth)
  const scroll = () => {
    scroller.scrollTo(!scrolledToEnd ? "last" : "first", {
      horizontal: true,
      duration: 800,
      spy: true,
      smooth: true,
      delay: 50,
      containerId: "scrollContainer",
    })
    setScrolledToEnd(!scrolledToEnd)
  }

  const totalImagesWidth =
    teamRituals.images.length * Number(imageWidth) +
    (teamRituals.images.length - 1) * Number(imageMargin)

  return (
    <Section className={className}>
      <StyledContentWrapper>
        <TextWrapper>
          {teamRituals.title && (
            <Title
              dangerouslySetInnerHTML={{
                __html: teamRituals.title,
              }}
            />
          )}
          {teamRituals.description && (
            <Text
              dangerouslySetInnerHTML={{
                __html: teamRituals.description,
              }}
            />
          )}
        </TextWrapper>

        {teamRituals.images && (
          <>
            <ScrollContainer id="scrollContainer">
              <>
                <StyledZigZagLine width={totalImagesWidth} />
                {teamRituals.images.map((image, index: number) => (
                  <ImageWrapper
                    key={`company-card-${index}`}
                    name={
                      index === 0
                        ? "first"
                        : index === teamRituals.images.length - 1
                        ? "last"
                        : index.toString()
                    }
                  >
                    <GatsbyImage fluid={image.url} alt={image.alt} />
                  </ImageWrapper>
                ))}
              </>
            </ScrollContainer>
            <Nav>
              <NavButton
                onMouseEnter={() => setIsHovered("left")}
                onMouseLeave={() => setIsHovered("")}
                disabled={!scrolledToEnd}
                onClick={() => (scrolledToEnd ? scroll() : undefined)}
              >
                <Icon
                  icon="arrowRight"
                  direction="left"
                  color={
                    isHovered === "left" ? colors.white : colors.purpleLight
                  }
                  width={24}
                  height={24}
                />
              </NavButton>
              <NavButton
                onMouseEnter={() => setIsHovered("right")}
                onMouseLeave={() => setIsHovered("")}
                disabled={scrolledToEnd}
                onClick={() => (!scrolledToEnd ? scroll() : undefined)}
              >
                <Icon
                  icon="arrowRight"
                  direction="right"
                  color={
                    isHovered === "right" ? colors.white : colors.purpleLight
                  }
                  width={24}
                  height={24}
                />
              </NavButton>
            </Nav>
          </>
        )}
      </StyledContentWrapper>
    </Section>
  )
}

export default TeamRituals

const Section = styled.section`
  padding: 96px 0;
  overflow: hidden;
  background: ${colors.main["800"]};

  ${mq.from.M`
    padding: 120px 0;
  `}
`

const StyledContentWrapper = styled(ContentWrapper)`
  position: relative;
`

const TextWrapper = styled(ContentWrapper)`
  max-width: 1200px;
`

const Title = styled.div`
  color: ${colors.purpleLight};
  ${textStyles.titleL}
  margin: 0 0 8px;

  > h3 {
    color: ${colors.purpleLight};
    margin: 0 0 8px;
  }

  ${mq.from.M`
  > h2 {
    font-size: 40px !important;
  }
`}
`

const Text = styled.div`
  > p {
    color: ${colors.main["300"]};
    ${textStyles.body}
    margin: 0 0 24px;
  }

  ${mq.from.M`
    > p {
      font-size: 20px;
    }
  `}

  > p:last-child {
    margin-bottom: 0;
  }
`

const StyledZigZagLine = styled(ZigZagLine)<{ width: number }>`
  position: absolute;
  left: 0;
  width: ${({ width }) => width}px;
  height: 100%;
  z-index: 0;
  bottom: 50px;
`

const ScrollContainer = styled.div`
  position: relative;
  overflow-x: scroll;
  display: flex;
  margin: 24px 0;

  /* Make sure content inside this container is always on top */
  & > *:not(${StyledZigZagLine}) {
    position: relative;
    z-index: 1;
  }

  /* Hide horizontal scrollbar for Webkit browsers */
  &::-webkit-scrollbar {
    height: 0;
  }

  /* Hide horizontal scrollbar for Firefox */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  ${mq.from.M`
    margin: 64px 0 48px;
    width: calc(50% + 50vw);
    padding-right: 24px;
  `}
`

const NavButton = styled.button`
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  transition: background-color 0.3s ease;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 4px;
  box-shadow: 0px 1px 0px rgba(42, 9, 134, 0.1);

  &:disabled {
    opacity: 0.5;
    cursor: default;
    background-color: ${colors.whiteGrey};

    &:hover {
      background-color: ${colors.whiteGrey};
    }
  }

  &:first-of-type {
    margin-right: 16px;
  }

  &:hover {
    background-color: ${colors.purpleLight};
  }
`
const Nav = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const ImageWrapper = styled(Element)`
  min-width: 240px;
  width: 100%;
  height: 100%;
  margin-right: 24px;
  border-radius: 12px;

  &:last-of-type {
    margin-right: 0;
  }

  ${mq.from.M`
    min-width: 340px;
`}
`
