import React from "react"
import styled from "styled-components"
import GatsbyImage from "gatsby-image"

// Styling
import colors from "../styles/colors"
import textStyles, { fontWeights } from "../styles/textStyles"
import mq from "../styles/breakpoints"

// Constants
import { paths, getUrl } from "../constants/paths"

// Components
import ContentWrapper from "./ContentWrapper"
import Button, { Variant } from "./atoms/Button"
import Link from "./atoms/Link"

// Custom Hooks
import { useLocale } from "../hooks/useLocale"

// Types
import { IExplanation, PageGroup } from "../prismic-types/homepage"

interface IProps extends IExplanation {
  smallImages?: boolean
  buttonVariant?: Variant
  className?: string
  textColor?: "dark" | "light"
  pageGroup: PageGroup
}

const Explanation: React.FC<IProps> = ({
  title,
  label,
  text,
  explanations,
  buttonLabel,
  buttonVariant,
  smallImages,
  className,
  textColor = "dark",
  pageGroup,
}) => {
  const { getLocalizedLink } = useLocale()
  return (
    <Container className={className} background={colors.backgroundBlue}>
      <ContentWrapper>
        <TextWrapper>
          {label && <Label>{label}</Label>}
          {title && (
            <Title
              dangerouslySetInnerHTML={{
                __html: title,
              }}
              color={textColor}
            />
          )}
          {text && (
            <Text
              pageGroup={pageGroup}
              color={textColor}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
          {buttonLabel && (
            <ButtonWrapper>
              <Button
                label={buttonLabel}
                variant={buttonVariant || "secondary"}
                href={getLocalizedLink(paths.demo)}
              />
            </ButtonWrapper>
          )}
        </TextWrapper>
        {explanations && (
          <Explanations>
            {explanations.map((explanation, index) => {
              if (index < 3) {
                return (
                  <React.Fragment key={`explanation-${index}`}>
                    <Column>
                      {explanation.image && explanation.image.url && (
                        <ImageWrapper small={smallImages}>
                          <GatsbyImage
                            fluid={explanation.image.url}
                            alt={explanation.image.alt || explanation.title}
                          />
                        </ImageWrapper>
                      )}
                      {explanation.label && (
                        <ColumnLabel>{explanation.label}</ColumnLabel>
                      )}
                      {explanation.title && (
                        <ColumnTitle>{explanation.title}</ColumnTitle>
                      )}
                      {explanation.description && (
                        <ColumnText
                          dangerouslySetInnerHTML={{
                            __html: explanation.description,
                          }}
                        />
                      )}
                      {explanation.links && explanation.links.length > 0 && (
                        <ColumnLinks>
                          {explanation.links.map((link, linkIndex) => (
                            <StyledLink
                              key={`column-link-${linkIndex}`}
                              to={getUrl(link.link)}
                              type="internal"
                              title={`${link.label}${
                                linkIndex ===
                                (explanation?.links?.length || 0) - 1
                                  ? ""
                                  : " | "
                              }`}
                              color={colors.purpleLight}
                            />
                          ))}
                        </ColumnLinks>
                      )}
                    </Column>
                  </React.Fragment>
                )
              }
            })}
          </Explanations>
        )}
      </ContentWrapper>
    </Container>
  )
}

export default Explanation

const Container = styled.section<{ background: string }>`
  position: relative;
  padding: 64px 0;
  background: ${({ background }) => background};

  ${mq.from.M`
    padding: 88px 0;
  `}

  ${mq.from.L`
    padding: 120px 0;
  `}
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${mq.from.M`
    margin: 0 auto;
    width: calc(10/12*100%);
  `}
`

const Label = styled.p`
  ${textStyles.titleS};
  color: ${colors.pink};
  margin: 0 0 16px;

  ${mq.from.M`
    margin-bottom: 24px;
  `}
`

const Text = styled.div<{ pageGroup: PageGroup }>`
  color: ${({ pageGroup }: { pageGroup: PageGroup }) =>
    pageGroup === "HomeAndPlatform" ? colors.main["300"] : colors.main["200"]};
  ${textStyles.body};
  margin-bottom: 0;

  > p {
    font-weight: ${({ pageGroup }: { pageGroup: PageGroup }) =>
      pageGroup === "HomeAndPlatform"
        ? fontWeights.medium
        : fontWeights.regular};
  }

  ${mq.to.XS`
    > p {
      text-align: justify;
    }
  `}
  ${mq.from.M`
  > p {
    font-size: ${({ pageGroup }: { pageGroup: PageGroup }) =>
      pageGroup === "HomeAndPlatform" ? "22px" : "18px"};
  }
`}

  > p:last-child {
    margin-bottom: 0;
  }
`

const Title = styled.div<{ color?: "dark" | "light" }>`
  > h1,
  h2,
  h3 {
    ${textStyles.titleL};

    ${mq.to.XS`
      font-size: 32px;
      text-align: left;
    `}
  }

  > h1 {
    color: ${({ color }) =>
      color === "dark" ? colors.main["200"] : colors.white};
  }

  > h2,
  > h3 {
    color: ${({ color }) =>
      color === "dark" ? colors.main["100"] : colors.white};
  }
  margin: 0 0 24px;
`

const Column = styled.div`
  padding: 16px 40px;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  margin-bottom: 32px;

  ${mq.from.M`
    width: calc(33% - 12px);
    padding: 16px 24px;
    align-self: flex-start;
    margin-bottom: 0;
  `}
`

const ImageWrapper = styled.div<{ small?: boolean }>`
  width: 100%;
  max-width: ${({ small }) => (small ? 150 : 300)}px;
  margin: 0 auto;

  ${mq.from.S`
    width: ${({ small }: { small: boolean }) => (small ? 50 : 90)}%;
  `}

  img {
    margin: 0;
  }
`

const ColumnTitle = styled.h5`
  color: ${colors.main["100"]};
  ${textStyles.titleM};
  margin: 24px 0;
`

const ColumnLabel = styled.label`
  ${textStyles.subject};
  color: ${colors.purpleLight};
  margin-top: 24px;
  display: block;
  font-weight: ${fontWeights.medium};
  text-transform: unset;
`

const ColumnText = styled.div`
  color: ${colors.main["100"]};
  ${textStyles.body};
  margin: 16px 0 0;

  p {
    margin: 0;
  }
`

const ColumnLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`

const StyledLink = styled(Link)`
  margin-right: 3px;
  font-weight: ${fontWeights.regular};
  transition: color 0.3s ease-in-out;

  &:hover {
    color: ${colors.purple};
  }

  &:after {
    display: none;
  }
`

const Explanations = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  ${mq.from.M`
    flex-direction: row;
    justify-content: space-between;
    margin-top: 64px;
  `}
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;

  > button:nth-of-type(2) {
    margin-top: 24px;
  }

  ${mq.from.M`
    flex-direction: row;
    justify-content: center;
    margin-top: 24px;

    > button:nth-of-type(2) {
      margin: 0 0 0 24px;
    }
  `}
`
