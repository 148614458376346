import React from "react"
import styled, { css } from "styled-components"
import GatsbyImage from "gatsby-image"

// Styling
import mq from "../styles/breakpoints"
import colors from "../styles/colors"
import { generateBackgroundStyles } from "../styles/stylingHelpers"
import textStyles, { fontWeights } from "../styles/textStyles"

// Components
import ContentWrapper from "./ContentWrapper"
import Button, { ColorScheme, Variant } from "./atoms/Button"

// Custom Hooks
import { useLocale } from "../hooks/useLocale"

// Constants
import { getUrl, paths } from "../constants/paths"
import zap from "../images/zap.svg"

// Types
import { Background, ITextImageLayout } from "../prismic-types/homepage"

interface IProps extends ITextImageLayout {
  buttonClick?: () => void
  lineColor?: string
  textColor?: string
  titleColor?: string
  mirrored?: boolean
  secondButton?: {
    label: string
    url: string
  }
  className?: string
  background: Background
  sectionId: string
  buttonVariant?: Variant
  buttonColorScheme?: ColorScheme
  titleFontSize?: string
}

const TextImageLayout: React.FC<IProps> = ({
  title,
  text,
  buttonLabel,
  desktopImg,
  className,
  mirrored,
  textColor = colors.black,
  titleColor = colors.black,
  buttonClick,
  secondButton,
  lineColor = colors.blue,
  background,
  label,
  sectionId,
  buttonVariant,
  titleFontSize = "64px",
  buttonLink,
}) => {
  const { getLocalizedLink } = useLocale()

  return (
    <Container id={sectionId} className={className} background={background}>
      <ContentWrapper>
        <InnerWrapper smaller={false}>
          <TextWrapper line={lineColor} fontSize={titleFontSize}>
            {label && <Label>{label}</Label>}
            {title && (
              <Title color={titleColor} as={"h1"}>
                {title}
              </Title>
            )}
            {text && (
              <Text
                color={textColor}
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            )}
            {(buttonLabel || secondButton) && (
              <ButtonRow>
                {buttonLabel && (
                  <Button
                    label={buttonLabel}
                    onClick={buttonClick}
                    href={getLocalizedLink(buttonLink || "")}
                    variant={buttonVariant}
                  />
                )}
                {secondButton && (
                  <Button
                    variant="secondary"
                    label={secondButton.label}
                    href={getLocalizedLink(
                      getUrl(secondButton.url) || paths.demo
                    )}
                  />
                )}
              </ButtonRow>
            )}
          </TextWrapper>
          <ImageWrapper mirrored={mirrored}>
            {desktopImg.url && (
              <GatsbyImage fluid={desktopImg.url} alt={desktopImg.alt} />
            )}
          </ImageWrapper>
        </InnerWrapper>
      </ContentWrapper>
    </Container>
  )
}

const Container = styled.section<{
  color?: string
  background: Background
}>`
  position: relative;
  padding: 96px 0;
  ${({ background }) => generateBackgroundStyles(background)}
  color: ${colors.text};

  ${mq.from.S`
    padding: 104px 0 96px;
  `}

  ${mq.from.M`
    padding: 168px 0 128px;
  `}
`

const ImageWrapper = styled.div<{ mirrored?: boolean }>`
  width: 100%;
  height: 100%;
  order: 0;
  margin-bottom: 40px;

  img {
    margin-bottom: 0;
  }

  ${mq.from.S`
    width: calc(50% - 16px);
    margin-bottom: 0;
    order: ${({ mirrored }: { mirrored?: boolean }) => (mirrored ? 0 : 2)};
  `}
`

const InnerWrapper = styled.div<{ smaller?: boolean }>`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;

  ${mq.from.S`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}

  ${mq.from.M`
    ${({ smaller }: { smaller: boolean }) =>
      smaller &&
      `
       width: calc(10/12*100%);
       margin-left: auto;
       margin-right: auto;
    `}
  `}
`

const Label = styled.label`
  ${textStyles.titleS};
  margin-bottom: 32px;
`

const TextWrapper = styled.div<{ line?: string; fontSize: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  order: 1;
  ${({ line }) =>
    line &&
    css`
      border-left: 1px solid ${line};
      padding-left: 24px;
    `}

  ${mq.from.S`
    width: calc(50% - 16px);
  `}

  > h1 {
    ${textStyles.titleXL};
    margin-bottom: 16px;
    font-style: bold;

    ${mq.from.S`
      margin-bottom: 24px;
    `}

    ${mq.from.M`
    font-size: ${({ fontSize }: { fontSize: string }) => fontSize || "64px"};
    `}
  }

  > h3 {
    ${textStyles.titleL};
    margin-bottom: 16px;

    ${mq.from.S`
      margin-bottom: 24px;
    `}
  }
`

const ButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  > button:nth-of-type(2) {
    margin-top: 8px;
  }

  ${mq.from.XS`
    flex-direction: row;
    align-items: center;

    > button:nth-of-type(2) {
      margin: 0 0 0 16px;
    }
  `}
`

const Title = styled.span<{ color?: string }>`
  ${({ color }) =>
    `color: ${color};
  `}
`

const Text = styled.div<{ color?: string }>`
  ${({ color }) =>
    `color: ${color};
`}

  > p {
    ${textStyles.body};
  }

  > b {
    font-weight: ${fontWeights.bold};
  }

  > p:last-child {
    margin-bottom: 0;
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      margin: 0 0 12px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: -26px;
        top: 4px;
        width: 18px;
        height: 18px;
        background-image: url("${zap}");
        background-position: center 50%;
        background-repeat: no-repeat;
      }
    }
  }
`

export default TextImageLayout
