import { graphql } from "gatsby"
import React, { useContext, useEffect } from "react"
import { scroller } from "react-scroll"
import styled from "styled-components"

// Context
import AppContext from "../context/AppContext"

// Custom Hooks
import { useLocale } from "../hooks/useLocale"

// Components
import Explanation from "../components/Explanation"
import Intro from "../components/Intro"
import Seo from "../components/Seo"
import Testimonials from "../components/Testimonials"
import TextImageLayout from "../components/TextImageLayout"
import BottomSection from "../components/BottomSection"
import TeamRituals from "../components/TeamRituals"

// Constants
import { getUrl } from "../constants/paths"

// Styles
import colors from "../styles/colors"

// Types
import { IHomePage, IntroVariant } from "../prismic-types/homepage"

interface IProps {
  content: IHomePage
}

const HomePage: React.FC<IProps> = ({ content }) => {
  const context = useContext(AppContext)
  const { getLocalizedLink } = useLocale()

  const seo = {
    title: content.meta.title,
    description: content.meta.description,
    lang: content.meta.lang,
    imageUrl: content.intro.desktopImg.imageUrl,
    datePublished: content.meta.publishedDate,
    dateModified: content.meta.modifiedDate,
  }
  const [
    marketingSection1,
    marketingSection2,
    marketingSection3,
  ] = content.marketingSections

  useEffect(() => {
    if (context && context.scrollToAnchor !== null) {
      scroller.scrollTo(context.scrollToAnchor, {
        duration: 800,
        spy: true,
        smooth: true,
      })
      context.setScrollToAnchor(null)
    }
  }, [])

  return (
    <Main>
      <Seo
        title={seo.title}
        description={seo.description}
        lang={seo.lang}
        imageUrl={seo.imageUrl}
        datePublished={seo.datePublished}
        dateModified={seo.dateModified}
      />
      <Intro
        {...content.intro}
        buttonVariant="primary"
        color="purple"
        buttonLink={getLocalizedLink("/contact")}
        textColor="dark"
        shapeColor={colors.white}
      />
      <StyledExplanation {...content.explanation} pageGroup="HomeAndPlatform" />
      {marketingSection1.image && (
        <TextImageLayout
          desktopImg={marketingSection1.image}
          title={marketingSection1.title || ""}
          text={marketingSection1.text || ""}
          buttonLabel={marketingSection1.button?.label}
          buttonLink={getUrl(marketingSection1.button?.link)}
          titleFontSize="48px"
          sectionId="our-services"
          titleColor={colors.main["100"]}
          textColor={colors.black}
          lineColor={colors.main[300]}
          background={{ type: "plain", color: colors.purpleLightest }}
        />
      )}
      {marketingSection2.image && (
        <TextImageLayout
          desktopImg={marketingSection2.image}
          title={marketingSection2.title || ""}
          text={marketingSection2.text || ""}
          buttonLabel={marketingSection2.button?.label}
          buttonLink={getUrl(marketingSection2.button?.link)}
          titleFontSize="48px"
          sectionId="our-method"
          titleColor={colors.white}
          textColor={colors.white}
          lineColor={colors.white}
          background={{
            type: "pattern",
            patternUrl: marketingSection2.backgroundImage?.url || "",
            backgroundColor: colors.purpleLight,
          }}
          buttonVariant="tertiary"
          buttonColorScheme="white"
        />
      )}
      {content.ritualCarousel && (
        <StyledTeamRituals teamRituals={content.ritualCarousel} />
      )}
      <SecondExplanation {...content.secondExplanation} pageGroup="Services" />
      <Testimonials {...content.testimonials} />
      {marketingSection3 && (
        <BottomSection
          title={marketingSection3.title || ""}
          text={marketingSection3.text || ""}
          buttonLabel={marketingSection3.button?.label}
          buttonLink={getUrl(marketingSection3.button?.link)}
          sectionId="our-story"
          titleColor={colors.purpleLight}
          textColor={colors.main["200"]}
          background={{
            type: "imageWithGradient",
            imageUrl: marketingSection3.backgroundImage?.url || "",
            gradientStartColor: colors.OffWhite,
            gradientEndColor: colors.purpleDarkestDim,
            gradientStartPercentage: 17,
            gradientEndPercentage: 100,
          }}
          buttonVariant="primary"
        />
      )}
    </Main>
  )
}

const Main = styled.div`
  background: ${colors.backgroundYellow};
`

const StyledExplanation = styled(Explanation)`
  background-color: ${colors.white};
`

const SecondExplanation = styled(Explanation)`
  background-color: ${colors.main["800"]};
`

const StyledTeamRituals = styled(TeamRituals)`
  background-color: ${colors.white};
`

export const query = graphql`
  query homePage($locale: String!) {
    prismicHomePage(lang: { eq: $locale }) {
      last_publication_date
      lang
      first_publication_date
      data {
        # Meta
        meta_title
        meta_description

        # Intro
        intro_title {
          text
        }
        intro_text {
          html
        }
        intro_image_desktop {
          alt
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
          url
        }
        intro_button_label

        # Explanation
        explanation_title {
          html
        }
        explanation_text {
          html
        }
        explanations {
          label
          title {
            text
          }
          description {
            html
          }
          image {
            alt
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          button_label
          button_link
          second_button_label
          second_button_link
          third_button_label
          third_button_link
        }

        # image or video
        image_or_video {
          raw
        }

        # Slider
        slides {
          service_type
          label
          title {
            text
          }
          text {
            html
          }
          button_label
          button_link
          image {
            alt
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }

        # Testimonials
        testimonials_title {
          html
        }
        testimonials_description {
          html
        }
        testimonials {
          image {
            alt
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          cover_image {
            alt
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          client_name
          client_position
          testimonial {
            html
          }
        }
        marketing_section {
          button_label
          button_link
          title {
            text
          }
          text {
            html
          }
          image {
            fluid {
              srcWebp
              srcSetWebp
              srcSet
              src
              sizes
              base64
              aspectRatio
            }
            url
            alt
          }
          background_image {
            url
            alt
          }
        }

        # Explanations 2
        columns_title {
          html
        }
        columns_text {
          html
        }
        columns {
          label
          title {
            text
          }
          description {
            html
          }
          button_label
          button_link
          image {
            alt
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
      }
    }
    allPrismicFeaturesPage(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          data {
            # Body (rituals)
            body {
              ... on PrismicFeaturesPageBodyTeamRitualCarousel {
                id
                slice_label
                slice_type
                primary {
                  description {
                    html
                    text
                  }
                  title1 {
                    html
                    text
                  }
                }
                items {
                  image {
                    alt
                    url
                    fluid {
                      aspectRatio
                      base64
                      sizes
                      src
                      srcSet
                      srcSetWebp
                      srcWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicEvaluationDetail(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            testimonials {
              logo {
                alt
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
              }
            }
          }
        }
      }
    }
    allPrismicManifestoPage(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          id
          data {
            logos_label
          }
        }
      }
    }
  }
`

export default ({ data }: any) => {
  const raw = data.prismicHomePage.data
  const clientLogos =
    data.allPrismicEvaluationDetail.edges[0].node.data.testimonials

  const rituals = data.allPrismicFeaturesPage.edges[0].node.data.body

  const meta = {
    title: raw.meta_title,
    description: raw.meta_description,
    publishedDate: data.prismicHomePage.first_publication_date,
    modifiedDate: data.prismicHomePage.last_publication_date,
    lang: data.prismicHomePage.lang,
  }

  const intro = {
    title: raw.intro_title.text,
    text: raw.intro_text.html,
    desktopImg: {
      alt: raw.intro_image_desktop.alt,
      url: raw.intro_image_desktop.fluid,
      imageUrl: raw.intro_image_desktop.url,
    },
    buttonLabel: raw.intro_button_label,
    variant: "home" as IntroVariant,
  }

  const explanation = {
    title: raw.explanation_title?.html,
    text: raw.explanation_text?.html,
    explanations: raw.explanations.map((exp: any) => ({
      title: exp.title?.text || "",
      description: exp.description?.html || "",
      label: exp.label,
      links:
        exp.button_link && exp.button_label
          ? [
              {
                link: exp.button_link,
                label: exp.button_label,
              },
              exp.second_button_link && exp.second_button_label
                ? {
                    link: exp.second_button_link,
                    label: exp.second_button_label,
                  }
                : undefined,
              exp.third_button_link && exp.third_button_label
                ? {
                    link: exp.third_button_link,
                    label: exp.third_button_label,
                  }
                : undefined,
            ].filter((val) => val !== undefined)
          : undefined,
      image: {
        alt: exp.image?.alt,
        url: exp.image?.fluid,
      },
    })),
  }

  const imageOrVideo = {
    media: {
      raw: raw.image_or_video?.raw,
    },
  }

  const secondExplanation = {
    title: raw.columns_title?.html,
    text: raw.columns_text?.html,
    explanations: raw.columns.map((col: any) => ({
      title: col.title?.text || "",
      description: col.description?.html || "",
      label: col.label,
      links:
        col.button_link && col.button_label
          ? [
              {
                link: col.button_link,
                label: col.button_label,
              },
            ]
          : undefined,
      image: {
        alt: col.image?.alt,
        url: col.image?.fluid,
      },
    })),
  }

  const service = {
    title: raw.intro_title.text,
    text: raw.intro_text.html,
    desktopImg: {
      alt: raw.intro_image_desktop.alt,
      url: raw.intro_image_desktop.fluid,
    },
    buttonLabel: raw.intro_button_label,
  }

  const ourStory = {
    title: raw.intro_title.text,
    text: raw.intro_text.html,
    buttonLabel: raw.intro_button_label,
  }

  const formattedSlides = raw.slides.map((slide: any) => ({
    title: slide.title.text,
    type: slide.service_type,
    label: slide.label,
    text: slide.text.html,
    button: {
      link: slide.button_link,
      label: slide.button_label,
    },
    image: {
      alt: slide.image?.alt,
      url: slide.image?.fluid,
    },
  }))

  const slider = {
    slides: formattedSlides,
  }

  const logos = {
    label: data.allPrismicManifestoPage.edges[0].node.data.logos_label,
    logos: clientLogos.map((testimonial: any) => ({
      alt: testimonial.logo?.alt,
      url: testimonial.logo?.fluid,
    })),
  }

  const testimonials = {
    title: raw.testimonials_title?.html,
    testimonials: raw.testimonials.map((testimonial: any) => ({
      image: {
        alt: testimonial.image?.alt,
        url: testimonial.image?.fluid,
      },
      coverImage: {
        alt: testimonial.cover_image?.alt,
        url: testimonial.cover_image?.fluid,
      },
      client: {
        name: testimonial.client_name,
        position: testimonial.client_position,
      },
      quote: testimonial.testimonial?.html,
    })),
  }

  const marketingSections = raw.marketing_section.map((section: any) => ({
    title: section.title.text,
    text: section.text.html,
    button: {
      link: section.button_link,
      label: section.button_label,
    },
    image: {
      alt: section.image?.alt,
      url: section.image?.fluid,
    },
    backgroundImage: {
      alt: section.background_image?.alt,
      url: section.background_image?.url,
    },
  }))

  const ritualCarousel = rituals
    .filter((slice: any) => slice.slice_type === "team_ritual_carousel")
    .map((carousel: any) => ({
      title: carousel.primary.title1.html,
      description: carousel.primary.description.html,
      images: carousel.items.map((item: any) => ({
        url: item.image.fluid,
        alt: item.image.alt,
      })),
    }))

  const content = {
    meta,
    intro,
    explanation,
    imageOrVideo,
    slider,
    logos,
    testimonials,
    ritualCarousel: ritualCarousel[0],
    service,
    secondExplanation,
    ourStory,
    marketingSections,
  }

  return <HomePage content={content} />
}
