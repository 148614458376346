import React from "react"
import styled, { css } from "styled-components"
import GatsbyImage from "gatsby-image"

// Styling
import colors from "../styles/colors"
import textStyles, { fontWeights } from "../styles/textStyles"
import mq from "../styles/breakpoints"
import { fonts } from "../styles/fonts"

// Componnets
import ContentWrapper from "./ContentWrapper"
import Button, { Variant } from "./atoms/Button"

// Constants
import { paths, getUrl } from "../constants/paths"
import zap from "../images/zap.svg"
import shape from "../images/intro-shape.svg"

// Types
import { IIntro, IntroVariant } from "../prismic-types/homepage"

// Custom Hooks
import { useLocale } from "../hooks/useLocale"
import { TitleTag } from "src/prismic-types/common"

interface IProps extends IIntro {
  titleTag?: TitleTag
  buttonClick?: () => void
  buttonLink?: string
  buttonVariant: Variant
  color?: "yellow" | "blue" | "purple"
  textColor?: "dark" | "light"
  mirrored?: boolean
  secondButton?: {
    label: string
    url: string
    variant: Variant
  }
  className?: string
  shapeColor: string
}

const Intro: React.FC<IProps> = ({
  title,
  titleTag = "h1",
  text,
  buttonLabel,
  buttonVariant,
  desktopImg,
  variant,
  className,
  mirrored,
  textColor = "dark",
  buttonLink,
  secondButton,
  color,
  label,
  shapeColor,
}) => {
  const { getLocalizedLink } = useLocale()
  const homeVariant = variant === "home"
  const backgroundColor =
    color === "blue"
      ? colors.blueLightest
      : color === "yellow"
      ? colors.yellowLight
      : colors.blueSoft

  const lineColor = color
    ? color === "blue"
      ? colors.blue
      : color === "yellow"
      ? colors.yellow
      : colors.purpleLight
    : undefined
  return (
    <Container
      id="intro"
      className={className}
      variant={variant}
      color={backgroundColor}
      shapeColor={shapeColor}
    >
      <ContentWrapper>
        <InnerWrapper smaller={variant !== "home"}>
          <TextWrapper line={lineColor}>
            {label && <Label>{label}</Label>}
            {title && (
              <Title color={textColor} as={titleTag}>
                {title}
              </Title>
            )}
            {text && (
              <Text
                homeVariant={homeVariant}
                color={textColor}
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            )}
            {(buttonLabel || secondButton) && (
              <ButtonRow>
                {buttonLabel && (
                  <Button
                    label={buttonLabel}
                    href={buttonLink}
                    variant={buttonVariant}
                  />
                )}
                {secondButton && (
                  <Button
                    label={secondButton.label}
                    href={getLocalizedLink(
                      getUrl(secondButton.url) || paths.demo
                    )}
                    variant={secondButton.variant}
                  />
                )}
              </ButtonRow>
            )}
          </TextWrapper>
          <ImageWrapper mirrored={mirrored}>
            {desktopImg.url && (
              <GatsbyImage fluid={desktopImg.url} alt={desktopImg.alt} />
            )}
          </ImageWrapper>
        </InnerWrapper>
      </ContentWrapper>
    </Container>
  )
}

const Container = styled.section<{
  variant: IntroVariant
  color?: string
  shapeColor?: string
}>`
  position: relative;
  padding: ${({ variant }) => (variant === "home" ? "96px 0" : "88px 0 72px")};
  background: ${({ variant, color }) =>
    variant === "home" || variant === "page"
      ? `linear-gradient(90deg, ${color} 12.47%, ${colors.greyLight} 95%), ${colors.greyLight};`
      : colors.white};
  color: ${colors.text};

  ${mq.from.S`
    padding: 104px 0 96px;
  `}

  ${({ variant, shapeColor }) =>
    (variant === "home" || variant === "page") &&
    `
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      -webkit-mask: url("${shape}");
      mask: url("${shape}");
      -webkit-mask-size: cover;
      mask-size: cover;
      width: 100%;
      height: 7vw;
      background-color: ${shapeColor};
    }
  `}

  ${mq.from.M`
    padding: 168px 0 128px;
  `}
`

const ImageWrapper = styled.div<{ mirrored?: boolean }>`
  width: 100%;
  height: 100%;
  order: 0;
  margin-bottom: 40px;

  img {
    margin-bottom: 0;
  }

  ${mq.from.S`
    width: calc(50% - 16px);
    margin-bottom: 0;
    order: ${({ mirrored }: { mirrored?: boolean }) => (mirrored ? 0 : 2)};
  `}
`

const InnerWrapper = styled.div<{ smaller?: boolean }>`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;

  ${mq.from.S`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}
`

const Label = styled.label`
  text-transform: uppercase;
  color: ${colors.purpleLight};
  ${textStyles.subject};
  margin-bottom: 32px;
`

const TextWrapper = styled.div<{ line?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  order: 1;
  ${({ line }) =>
    line &&
    css`
      border-left: 1px solid ${line};
      padding-left: 24px;
    `}

  ${mq.from.S`
    width: calc(50% - 16px);
  `}

  > h1 {
    ${textStyles.titleXL};
    margin-bottom: 16px;

    ${mq.from.S`
      margin-bottom: 24px;
    `}

    ${mq.from.M`
    font-size: 64px;
    `}
  }

  > h2,
  h3 {
    ${textStyles.titleL};
    margin-bottom: 16px;

    ${mq.from.S`
      margin-bottom: 24px;
    `}

    ${mq.from.M`
    font-size: 48px;
    `}
  }
`

const ButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  row-gap: 12px;

  > button:nth-of-type(2) {
    margin-top: 8px;
  }

  ${mq.from.XS`
    flex-direction: row;
    align-items: center;
    column-gap: 12px;
    > button:nth-of-type(2) {
      margin: 0 0 0 16px;
    }
  `}
`

const Title = styled.span<{ color: "dark" | "light" }>`
  ${({ color }) =>
    color === "light"
      ? `
    color: ${colors.white};
  `
      : `
    color: ${colors.main["100"]};
  `}
  font-family: ${fonts.primary};
  font-weight: 800;
  letter-spacing: -2px;
`

const Text = styled.div<{ homeVariant: boolean; color: "dark" | "light" }>`
  ${({ color }) =>
    color === "light"
      ? `
    color: ${colors.white};
  `
      : `
    color: ${colors.main["200"]};
  `}

  > p {
    ${textStyles.body};
  }

  > b {
    font-weight: ${fontWeights.bold};
  }

  > p:last-child {
    margin-bottom: 0;
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      margin: 0 0 12px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: -26px;
        top: 4px;
        width: 18px;
        height: 18px;
        background-image: url("${zap}");
        background-position: center 50%;
        background-repeat: no-repeat;
      }
    }
  }
`

export default Intro
